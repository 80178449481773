import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/226424284-25f5e2d1-4986-48b3-b856-20a227925eae.png" />
    <h2>{`Usage`}</h2>
    <p>{`Use a textarea to allow users to input a long string of free-form text.`}</p>
    <h3>{`States`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/226424285-ba0f63cb-f1fe-42bb-b5f4-1a77ec8f69f5.png" />
    <p><strong parentName="p">{`Focused:`}</strong>{` The input has been navigated to and is ready to accept input.`}</p>
    <p><strong parentName="p">{`Disabled:`}</strong>{` The input cannot accept input or become focused.`}</p>
    <p><strong parentName="p">{`Invalid:`}</strong>{` The value failed validation.`}</p>
    <p><strong parentName="p">{`Valid:`}</strong>{` The value passed validation.`}</p>
    <p>{`For more information about the "valid" and "invalid" states, see the `}<a parentName="p" {...{
        "href": "../ui-patterns/forms/overview#validation"
      }}>{`validation section`}</a>{` of the form design pattern guidelines.`}</p>
    <h3>{`Best practices`}</h3>
    <h2>{`Anatomy`}</h2>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/6905903/274239156-064abae9-6d16-4c1a-b673-ec8f96429ed9.png" />
    <p><strong parentName="p">{`Label (required):`}</strong>{` The input's title. It should be as concise as possible and convey the purpose of the input. The label may be visually hidden in rare cases, but a label must be defined for assistive technologies such as a screen reader.`}</p>
    <p><strong parentName="p">{`Required indicator:`}</strong>{` Indicates that a value is required. Must be shown for any required field, even if all fields in the form are required.`}</p>
    <p><strong parentName="p">{`Input (required):`}</strong>{` Accepts user free-form text input`}</p>
    <p><strong parentName="p">{`Caption:`}</strong>{` Provides additional context about the field to help users fill in the correct data or explain how the data will be used. Caption text should be as short as possible. Caption text may be displayed at the same time as a validation message, or it may be hidden if it only provides redundant information.`}</p>
    <p><strong parentName="p">{`Validation message:`}</strong>{` A single validation message may be displayed to provide helpful information for a user to complete their task. It is most commonly used to explain why a value is invalid so they can correct it and submit the form.`}</p>
    <h2>{`Options`}</h2>
    <h3>{`Width`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/226424288-001f5f16-94d3-445e-8e61-d7f18acd39b5.png" />
    <p>{`By default, a textarea will the width of a line of 30 characters.`}</p>
    <p>{`You can change this to be any number of characters, or render the textarea to fill the width of its parent element.`}</p>
    <h3>{`Height`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/226424290-d32d48a0-9b6b-4899-8c45-2031bbd09a1f.png" />
    <p>{`By default, a textarea will the height of 7 lines of text.`}</p>
    <p>{`You can change this to be any number of lines. Consider the length of the expected value to set a height.`}</p>
    <p>{`If the height of the content exceeds the height of textarea, users can scroll the content or resize the textarea.`}</p>
    <h3>{`Resizing`}</h3>
    <p>{`By default, a textarea can be resized vertically and horizontally.`}</p>
    <p>{`You can limit a user's resizing options to:`}</p>
    <ul>
      <li parentName="ul">{`no resizing`}</li>
      <li parentName="ul">{`only resize vertically`}</li>
      <li parentName="ul">{`only resize horizontally`}</li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <ul>
      <li parentName="ul">{`Textareas must be labeled. The label may be visually hidden, but visible labels are preferred.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/guides/accessibility/placeholders"
        }}>{`Placeholder text`}</a>{` is `}<strong parentName="li">{`never`}</strong>{` an acceptable substitute for a label because:`}
        <ul parentName="li">
          <li parentName="ul">{`The placeholder text disappears as soon as the input has a value`}</li>
          <li parentName="ul">{`Placeholder text colors are typically too light to meet the minimum color contrast ratio required for accessibility`}</li>
          <li parentName="ul">{`Screen readers do not read placeholder text as a label`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Textarea" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../ui-patterns/forms/overview"
        }}>{`Forms`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/text-input"
        }}>{`Text input`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      